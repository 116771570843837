import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import "./GiftVoucherPage.css";
import { ReactComponent as HelpIcon } from "../assets/help_icon.svg"; // Import the Help icon SVG
import { FaTimes } from 'react-icons/fa'; // Importing FaTimes for the close button icon

const PaymentSuccessPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    toast.success('Payment was successful!');
  }, []);

  return (
    <>
      <div className="top-nav">
        <div className="nav-container">
          <div className="top-nav-left">
            <div className="logo">
              <a href="https://kmmaltairlines.com">
                <img src="/km_white_logo.svg" alt="KM Malta Airlines Logo" />
              </a>
            </div>
            <div className="burger-div">
              <button
                className="burger-menu-button"
                onClick={() => setIsModalOpen(true)}
              >
                ☰
              </button>
            </div>

            {isModalOpen && (
              <div className="burger-modal">
                <button
                  className="close-modal-button"
                  onClick={() => setIsModalOpen(false)}
                >
                  <FaTimes />
                </button>
                <div className="modal-content">
                  <div className="mobile-urls">
                    <a className="nav-url" href="https://kmmaltairlines.com">
                      Back to the website
                    </a>
                    <hr
                      className="mobile-nav-separator"
                      width="100%"
                      color="black"
                      size="1px"
                      align="left"
                    ></hr>
                    <a
                      className="nav-url"
                      href="https://help.kmmaltairlines.com/s/"
                    >
                      Help
                    </a>
                  </div>
                </div>
              </div>
            )}
            <div className="nav-links home-link">
              <a className="nav-url" href="https://kmmaltairlines.com">
                <span>&nbsp;&nbsp;&nbsp;Back to the website</span>
              </a>
            </div>
          </div>
          <div className="nav-right">
            <div className="help-link">
              <HelpIcon className="help-icon" />
              <a className="nav-url" href="https://help.kmmaltairlines.com/s/">
                <span>Help</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="breadcrumb">Home &gt; Gift Vouchers</div>

      <div className="header-section">
        <h1 className="hero-text">KM Gift Vouchers</h1>
      </div>

     

      <>
        
        <div className="payment-section">
        <h1 className="payment-form-title">Payment successful</h1>
          <div className="success-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="green" viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8" fill="green" />
                <path fill="white" d="M6.5 10.5L4 8l1.5-1.5L6.5 8l4-4L12 5l-5.5 5.5z"/>
            </svg>
          </div>
          <h3>Thank you for your purchase!</h3>
          <div className="summary-left"></div>
        </div>
      </>

      <footer className="footer">
        <div className="footer-links">
          <div className="footer-column">
            <h4>KM Malta Airlines Ltd.</h4>
            <ul>
              <li>
                <a href="https://kmmaltairlines.com/en/about-km-malta-airlines">
                  About Us
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/press">Press Room</a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/legal-and-policies">
                  Legal and Policies
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/careers">Careers</a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Help & Support</h4>
            <ul>
              <li>
                <a href="https://kmmaltairlines.com/en/customer-support">
                  Customer Support
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/travel-documents">
                  Travel Documents
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/assistance">
                  Assistance
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/young-travellers">
                  Young Travellers
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Plan & Manage</h4>
            <ul>
              <li>
                <a href="https://kmmaltairlines.com/">Book your Flight</a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/">Manage Your Booking</a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/flights">
                  Find Best Fares
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/km-destinations">
                  Destinations
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/route-map">Route Map</a>
              </li>
            </ul>
          </div>
          <div className="footer-apps">
            <div className="app-store">
              <a href="https://apps.apple.com/us/app/km-malta-airlines/id6499222465">
                <img src="/App-Store@2x.webp" alt="Download on the App Store" />
              </a>
            </div>
            <div className="google-play">
              <a href="https://play.google.com/store/apps/details?id=com.maltairlines">
                <img src="/Google-Play@2x.webp" alt="Get it on Google Play" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>2024 &copy; KM Malta Airlines Limited. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default PaymentSuccessPage;
