import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import GiftVoucherPage from './components/GiftVoucherPage';
import PaymentFailurePage from './components/PaymentFailurePage';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <BrowserRouter>
      <Toaster />
      <Routes>
        <Route path="/" element={<GiftVoucherPage />} />
        <Route path="/payment-success" element={<PaymentSuccessPage />} />
        <Route path="/payment-failed" element={<PaymentFailurePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;