import React, { useState, useEffect } from "react";
import "./GiftVoucherPage.css";
import { ReactComponent as HelpIcon } from "../assets/help_icon.svg"; // Import the Help icon SVG
import PhoneInput from "react-phone-input-2";
import { FaTimes } from "react-icons/fa"; // Import the icons from react-icons
import toast from 'react-hot-toast';
import { CountryDropdown } from 'react-country-region-selector';

import "react-phone-input-2/lib/bootstrap.css";
//import markerSDK from "@marker.io/browser";

//const widget = await markerSDK.loadWidget({
//  project: "6708f084a4e048cd1914280b",
//});

// GiftVoucherPage Component
const GiftVoucherPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [voucherTypes] = useState([
    "Generic",
    "Christmas",
    "Birthday",
    "Wedding",
    "Anniversary",
    "Graduation",
    "Holiday",
    "Congratulations",
    "ThankYou"
  ]);
  const [voucherTypeIndex, setVoucherTypeIndex] = useState(
    () => parseInt(localStorage.getItem("voucherTypeIndex")) || 0
  );
  const [customMessage, setCustomMessage] = useState(
    () => localStorage.getItem("customMessage") || ""
  );
  const [currency, setCurrency] = useState(
    () => localStorage.getItem("currency") || "EUR"
  );
  const [value, setValue] = useState(() => localStorage.getItem("value") || "");
  const [step, setStep] = useState(
    () => parseInt(localStorage.getItem("step")) || 1
  );
  const [phone, setPhone] = useState(() => localStorage.getItem("phone") || "");
  const [sendToRecipient, setSendToRecipient] = useState(
    () => JSON.parse(localStorage.getItem("sendToRecipient")) || false
  );
  const [acceptTerms, setAcceptTerms] = useState(
    () => JSON.parse(localStorage.getItem("acceptTerms")) || false
  );
  const [firstName, setFirstName] = useState(
    () => localStorage.getItem("firstName") || ""
  );
  const [lastName, setLastName] = useState(
    () => localStorage.getItem("lastName") || ""
  );
  const [country, setCountry] = useState(
    () => localStorage.getItem("country") || ""
  );
  const [city, setCity] = useState(
    () => localStorage.getItem("city") || ""
  );
  const [email, setEmail] = useState(() => localStorage.getItem("email") || "");
  const [recipientFirstName, setRecipientFirstName] = useState(
    () => localStorage.getItem("recipientFirstName") || ""
  );
  const [recipientLastName, setRecipientLastName] = useState(
    () => localStorage.getItem("recipientLastName") || ""
  );
  const [recipientEmail, setRecipientEmail] = useState(
    () => localStorage.getItem("recipientEmail") || ""
  );
  const [recipientPhone, setRecipientPhone] = useState(
    () => localStorage.getItem("recipientPhone") || ""
  );
  const [deliveryDateTime, setDeliveryDateTime] = useState(
    () => localStorage.getItem("deliveryDateTime") || ""
  );
  const [deliveryDate, setDeliveryDate] = useState(() => localStorage.getItem('deliveryDate') || '');
  const [deliveryTime, setDeliveryTime] = useState(() => localStorage.getItem('deliveryTime') || '');

  // Persist form state in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("voucherTypeIndex", voucherTypeIndex);
    localStorage.setItem("customMessage", customMessage);
    localStorage.setItem("currency", currency);
    localStorage.setItem("value", value);
    localStorage.setItem("step", step);
    localStorage.setItem("phone", phone);
    localStorage.setItem("sendToRecipient", sendToRecipient);
    localStorage.setItem("acceptTerms", acceptTerms);
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    localStorage.setItem("email", email);
    localStorage.setItem("country", country);
    localStorage.setItem("city", city);
    localStorage.setItem("recipientFirstName", recipientFirstName);
    localStorage.setItem("recipientLastName", recipientLastName);
    localStorage.setItem("recipientEmail", recipientEmail);
    localStorage.setItem("recipientPhone", recipientPhone);
    localStorage.setItem("deliveryDateTime", deliveryDateTime);
    localStorage.setItem("deliveryDate", deliveryDate);
    localStorage.setItem("deliveryTime", deliveryTime);
  }, [
    voucherTypeIndex,
    customMessage,
    currency,
    value,
    step,
    phone,
    sendToRecipient,
    acceptTerms,
    firstName,
    lastName,
    email,
    country,
    city,
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    recipientPhone,
    deliveryDate,
    deliveryTime,
    deliveryDateTime
  ]);

  //widget.setCustomData({
  //  firstName: firstName,
  //});

  // Clear localStorage when page is refreshed or closed
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.clear();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Handle form submission
  const handleSubmit = (e) => {
    
    e.preventDefault();
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      // Final form submission logic, e.g., redirect to payment or display confirmation
      console.log({
        voucherType: voucherTypes[voucherTypeIndex],
        customMessage,
        currency,
        value,
      });
      postFormData();
    }  /* else if (step === 4) {
      
    } */
  };
  const postFormData = async () => {
      const body = JSON.stringify({
          voucherType: voucherTypes[voucherTypeIndex],
          customMessage : customMessage,
          currency : currency,
          value: value,
          phone: phone,
          sendToRecipient: sendToRecipient,
          acceptTerms: acceptTerms,
          firstName : firstName,
          lastName : lastName,
          email : email,
          country: country,
          city : city,
          recipientFirstName : recipientFirstName,
          recipientLastName: recipientLastName,
          recipientEmail: recipientEmail,
          deliveryDateTime :  deliveryDateTime
          
        });
       const res = await fetch(`${process.env.REACT_APP_API_URL}api/voucher-submit`, {
        method: "POST",
        body: body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      const json = await res.json()
      if (json.isSuccess){
        window.open(json.redirectUrl, '_self', 'noopener,noreferrer');
      }else {
        toast(json.errorMessage);
      }
  }
  // Handle next and previous voucher type selection
  const handleNextVoucher = () => {
    setVoucherTypeIndex((prevIndex) => (prevIndex + 1) % voucherTypes.length);
  };

  const handlePreviousVoucher = () => {
    setVoucherTypeIndex(
      (prevIndex) => (prevIndex - 1 + voucherTypes.length) % voucherTypes.length
    );
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <div className="gift-voucher-page">
      {/* Top Navigation */}
      <div className="top-nav">
        <div className="nav-container">
          <div className="top-nav-left">
            <div className="logo">
              <a href="https://kmmaltairlines.com">
                <img src="/km_white_logo.svg" alt="KM Malta Airlines Logo" />
              </a>
            </div>
            <div className="burger-div">
              <button
                className="burger-menu-button"
                onClick={() => setIsModalOpen(true)}
              >
                ☰
              </button>
            </div>
            {/* Modal for Mobile Navigation */}
            {isModalOpen && (
              <div className="burger-modal">
                <button
                  className="close-modal-button"
                  onClick={() => setIsModalOpen(false)}
                >
                  <FaTimes />
                </button>
                <div className="modal-content">
                  <div className="mobile-urls">
                    <a className="nav-url" href="https://kmmaltairlines.com">
                      Back to the website
                    </a>
                    <hr
                      className="mobile-nav-separator"
                      width="100%"
                      color="black"
                      size="1px"
                      align="left"
                    ></hr>
                    <a
                      className="nav-url"
                      href="https://help.kmmaltairlines.com/s/"
                    >
                      Help
                    </a>
                    <a
                      className="nav-url"
                      target="_blank"
                      href="https://kmmaltairlines.com/en/campaigns/gift-vouchers-faqs"
                    >
                      FAQ
                    </a>
                  </div>
                </div>
              </div>
            )}
            <div className="nav-links home-link">
              <a className="nav-url" href="https://kmmaltairlines.com">
                <span>&nbsp;&nbsp;&nbsp;Back to the website</span>
              </a>
            </div>
          </div>
          <div className="nav-right">
            <div className="help-link">
              <HelpIcon className="help-icon" />
              <a className="nav-url" href="https://help.kmmaltairlines.com/s/">
                <span>Help</span>
              </a>
            </div>
            <div className="help-link">
              <a className="nav-url" target="_blank" href="https://kmmaltairlines.com/en/campaigns/gift-vouchers-faqs">
                <span>FAQs</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Breadcrumb Section */}
      <div className="breadcrumb">Home &gt; Gift Vouchers</div>

      {/* Header Section */}
      <div className="header-section">
        <h1 className="hero-text">KM Gift Vouchers</h1>
      </div>

      {/* Gift Description Section */}
      <div className="gift-description">
        <h2>Give the gift of travelling.</h2>
        <p>
          Looking for the perfect gift that offers endless possibilities?
          Whether it's a dream vacation, a spontaneous getaway, or a chance to
          reunite with loved ones, our vouchers make it easy to give the gift of
          travel. Make someone's day extraordinary and let them soar to new
          heights with our gift vouchers.
        </p>
      </div>

      {/* Steps Navigation */}
      <div className="steps-navigation">
        <div
          className={`step ${step === 1 ? "active" : ""}`}
          //onClick={() => setStep(1)}
        >
          <span className="step-number">1</span> Customise
        </div>
        <div
          className={`step ${step === 2 ? "active" : ""}`}
          //onClick={() => setStep(2)}
        >
          <span className="step-number">2</span> Details
        </div>
        <div
          className={`step ${step === 3 ? "active" : ""}`}
          //onClick={() => setStep(3)}
        >
          <span className="step-number">3</span> Summary
        </div>
        <div 
          className={`step ${step === 4 ? "active" : ""}`}
          //onClick={() => setStep(4)}
        >
          <span className="step-number">4</span> Confirmation
        </div>
      </div>

      {/* Multi-Step Form */}
      <form className="voucher-form" onSubmit={handleSubmit}>
        {step === 1 && (
          <>
            <h2 className="form-title">Customise Your Gift Voucher</h2>

            {/* Type of Voucher Selection */}
            <div className="voucher-type">
              <label className="input-label">Type of Voucher</label>
              <div className="voucher-carousel">
                <button
                  type="button"
                  className="carousel-button"
                  onClick={handlePreviousVoucher}
                >
                  ❮
                </button>
                <div className="voucher-card">
                  <img
                    src={`/images/${voucherTypes[
                      voucherTypeIndex
                    ].toLowerCase()}-voucher.png`}
                    alt="Voucher"
                  />
                  <p className="voucher-type-text">
                    {voucherTypes[voucherTypeIndex]}
                  </p>
                </div>
                <button
                  type="button"
                  className="carousel-button"
                  onClick={handleNextVoucher}
                >
                  ❯
                </button>
              </div>
            </div>

            {/* Custom Message Input */}
            <div className="custom-message">
              <label htmlFor="customMessage" className="input-label">
                Custom Message (optional)
              </label>
              <textarea
                id="customMessage"
                placeholder="Write the message you would like to appear on the Gift Voucher."
                value={customMessage}
                rows="4"
                onChange={(e) => setCustomMessage(e.target.value)}
                maxLength="120"
              ></textarea>
              <p className="character-count">
                Characters left: {120 - customMessage.length}
              </p>
            </div>

            {/* Value of Gift Voucher */}
            <div className="voucher-value">
              <label className="input-label">Value of Gift Voucher</label>
              <div className="currency-value-selectors">
                <div className="currency-selector">
                  <select
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option value="" disabled>
                      Select a currency
                    </option>
                    <option value="EUR">EUR</option>
                  </select>
                </div>
                <div className="value-selector">
                  <select
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select a value
                    </option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                    <option value="300">300</option>
                    <option value="350">350</option>
                    <option value="400">400</option>
                    <option value="450">450</option>
                    <option value="500">500</option>
                    <option value="550">550</option>
                    <option value="600">600</option>
                    <option value="650">650</option>
                    <option value="700">700</option>
                    <option value="750">750</option>
                    <option value="800">800</option>
                    <option value="850">850</option>
                    <option value="900">900</option>
                    <option value="950">950</option>
                    <option value="1000">1000</option>
                  </select>
                </div>
              </div>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <h2 className="form-title">Your Contact Details</h2>
            <div className="contact-details">
              <div className="input-group">
                <input
                  type="text"
                  className="input-field"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  className="input-field"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <PhoneInput
                  country={"mt"}
                  enableSearch={true}
                  value={phone}
                  preferredCountries={["mt", "gb", "fr", "de", "it"]}
                  inputClass="input-field"
                  searchClass="input-field"
                  searchPlaceholder="Search"
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: false,
                  }}
                  containerClass="input-group"
                  placeholder="Please enter your phone number"
                  defaultMask={".... ...."}
                  onChange={(phone) => setPhone(phone)}
                />
              </div>
              
              <div className="input-group">
                <input
                  type="email"
                  className="input-field"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="input-group">
                <CountryDropdown
                  value={country}
                  onChange={(country) => setCountry(country)}
                  classes="input-field" 
                />
                <input
                  type="text"
                  className="input-field"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </div>
            </div>

            <h2 className="form-title">Recipient Details</h2>
            <div className="recipient-details">
              <div className="input-group">
                <input
                  type="text"
                  className="input-field"
                  value={recipientFirstName}
                  placeholder="First Name"
                  onChange={(e) => setRecipientFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  className="input-field"
                  value={recipientLastName}
                  placeholder="Last Name"
                  onChange={(e) => setRecipientLastName(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                  <PhoneInput
                    enableSearch={true}
                    value={recipientPhone}
                    preferredCountries={["mt", "gb", "fr", "de", "it"]}
                    inputClass="input-field"
                    searchClass="input-field"
                    searchPlaceholder="Search"
                    inputProps={{
                      name: "recipientPhone",
                      required: true,
                      autoFocus: false,
                    }}
                    containerClass="input-group"
                    placeholder="Please enter the recipient's phone number"
                    defaultMask={".... ...."}
                    onChange={(recipientPhone) => setRecipientPhone(recipientPhone)}
                  />
                </div>
              <div className="checkbox-group">
                <input
                  type="checkbox"
                  id="send-email"
                  className="toggle-checkbox"
                  checked={sendToRecipient}
                  onChange={() => setSendToRecipient(!sendToRecipient)}
                />
                <label htmlFor="send-email" className="toggle-label">
                  Tick this box if you would like the gift voucher to be sent to
                  the recipient via email
                </label>
              </div>
              {sendToRecipient && (
                <div className="input-group">
                  <input
                    type="email"
                    className="input-field"
                    value={recipientEmail}
                    onChange={(e) => setRecipientEmail(e.target.value)}
                    required={sendToRecipient}
                    placeholder="Recipient Email address"
                  />
                </div>

              )}

              
            </div>

            {sendToRecipient && (
              <>
                <h2 className="form-title">Scheduled Delivery</h2>
                <div className="scheduled-delivery">
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="MM/DD/YYYY HH:MM"
                      value={deliveryDateTime}
                      onChange={(e) => {
                        const dateTime = e.target.value;
                        setDeliveryDateTime(dateTime);
                        const [date, time] = dateTime.split('T');
                        setDeliveryDate(date);
                        setDeliveryTime(time);
                      }}
                      onFocus={(e) => (e.target.type = "datetime-local")}
                      onBlur={(e) => (e.target.type = "text")}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {step === 3 && (
          <>
            <h2 className="form-title">Summary of Your Gift Voucher</h2>
            <div className="summary-section">
              <div className="summary-left">
                <p>
                  <strong>Type of Voucher:</strong>{" "}
                  {voucherTypes[voucherTypeIndex]}
                </p>
                <p>
                  <strong>Custom Message:</strong>{" "}
                  {customMessage || "No message provided"}
                </p>
                <p>
                  <strong>Value of Voucher:</strong> {value} {currency}
                </p>
                <p>
                  <strong>Phone Number:</strong> {phone}
                </p>
                <p>
                  <strong>First Name:</strong> {firstName}
                </p>
                <p>
                  <strong>Last Name:</strong> {lastName}
                </p>
                <p>
                  <strong>Email:</strong> {email}
                </p>
              </div>
              {sendToRecipient && (
                <>
                  <div className="summary-right">
                    <p>
                      <strong>Recipient Details:</strong>
                    </p>
                    <p>
                      <strong>First Name: </strong> {recipientFirstName}
                    </p>
                    <p>
                      <strong>Last Name:</strong> {recipientLastName}
                    </p>
                    <p>
                      <strong>Email Address:</strong> {recipientEmail}
                    </p>
                    <p>
                      <strong>Delivery Date & Time:</strong> {deliveryDateTime}
                    </p>
                  </div>
                </>
              )}
            </div>

            {/* Accept Terms and Conditions with new link */}
            <div className="terms-and-conditions">
              <input
                type="checkbox"
                id="accept-terms"
                className="form-checkbox"
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              />
              <label htmlFor="accept-terms">
                {" "}
                By submitting this form, I accept the{" "}
                <a
                  href="https://kmmaltairlines.com/gift-vouchers/gift-voucher-terms-and-conditions.pdf"
                  rel="noreferrer noopener"
                  target="_blank"
                  className="inline-url"
                >
                  Terms & Conditions
                </a>{" "}
                of KM Malta Airlines*
              </label>
            </div>
          </>
        )}

        {step === 4 && (
          <>
            <h2 className="form-title">Payment</h2>
            <div className="payment-section">
              <div className="success-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="green" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" fill="green" />
                    <path fill="white" d="M6.5 10.5L4 8l1.5-1.5L6.5 8l4-4L12 5l-5.5 5.5z"/>
                </svg>
              </div>
              <h3>Thank you for your purchase!</h3>
              <div className="summary-left">

              {sendToRecipient && (
              <>
                <p>The voucher will be delivered to {recipientEmail} on {deliveryDate} at {deliveryTime}.</p>
              </>
            )}
                
              </div>
              
            </div>

          </>
        )}

        {/* Submit Button */}
        
        <div className="form-buttons">
          {step > 1 && step < 4 && (
            <button type="button" className="back-button" onClick={handleBack}>
              &lt;{" "}
              {step === 2 ? "Customise" : step === 3 ? "Details" : "Summary"}
            </button>
          )}
          {step < 4 &&
            <button
            type="submit"
            className="submit-button continue-right"
            disabled={step === 3 && !acceptTerms}
          >
            {step === 1 ? 'Continue' : step === 2 ? 'Summary' : 'Proceed to Payment'}
          </button>}
        </div>
      </form>

      <footer className="footer">
        <div className="footer-links">
          <div className="footer-column">
            <h4>KM Malta Airlines Ltd.</h4>
            <ul>
              <li>
                <a href="https://kmmaltairlines.com/en/about-km-malta-airlines">
                  About Us
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/press">Press Room</a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/legal-and-policies">
                  Legal and Policies
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/careers">Careers</a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Help & Support</h4>
            <ul>
              <li>
                <a href="https://kmmaltairlines.com/en/customer-support">
                  Customer Support
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/travel-documents">
                  Travel Documents
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/assistance">
                  Assistance
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/young-travellers">
                  Young Travellers
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Plan & Manage</h4>
            <ul>
              <li>
                <a href="https://kmmaltairlines.com/">Book your Flight</a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/">Manage Your Booking</a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/flights">
                  Find Best Fares
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/km-destinations">
                  Destinations
                </a>
              </li>
              <li>
                <a href="https://kmmaltairlines.com/en/route-map">Route Map</a>
              </li>
            </ul>
          </div>
          <div className="footer-apps">
            <div className="app-store">
              <a href="https://apps.apple.com/us/app/km-malta-airlines/id6499222465">
                <img src="/App-Store@2x.webp" alt="Download on the App Store" />
              </a>
            </div>
            <div className="google-play">
              <a href="https://play.google.com/store/apps/details?id=com.maltairlines">
                <img src="/Google-Play@2x.webp" alt="Get it on Google Play" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>2024 &copy; KM Malta Airlines Limited. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default GiftVoucherPage;
